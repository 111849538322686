import React from 'react';
import { Grid, Tooltip } from '@jvs-group/jvs-mairistem-composants';
import {
  BarSeries,
  Chart,
  ScaleType,
  Settings,
} from '@elastic/charts';
import { clamp } from 'lodash';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import type PanelDataFromPeriode from '../interfaces/PanelDataFromPeriode';
import type PanelTooltipProps from '../interfaces/panelTooltipProps';
import './EpargneBrute.less';

interface EpargneBruteProps extends PanelTooltipProps{
  data: PanelDataFromPeriode;
}

const EpargneBrute = ({
  data,
  tooltip,
}: EpargneBruteProps) => {
  const epargneBrute = data?.epargneBrute ?? 0;
  const cumul = epargneBrute + (data?.depenseReelFonctionnement ?? 0);
  const taux = clamp(cumul > 0 ? (epargneBrute * 100) / cumul : 0, 0, 100);
  const chartData = [
    { x: 'Epargne Brute N-2', y: data?.exercicePrecedent2?.epargneBrute?.toFixed(2), g: 'Valeur' },
    { x: 'Epargne Brute N-1', y: data?.exercicePrecedent?.epargneBrute?.toFixed(2), g: 'Valeur' },
    { x: 'Epargne Brute', y: data?.epargneBrute?.toFixed(2), g: 'Valeur :' },
  ];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Tooltip
            message={tooltip}
            trigger={(
              <div className="bold indicateurTitle">
                {`Epargne brute ${data?.anneeExercice}`}
              </div>
            )}
            type="information"
          />

          <br />
          <br />
          Prévisions :
          <br />
          {formatMontant(epargneBrute)}
          <br />
          <br />
          Taux:
          {' '}
          {taux?.toFixed(2)}
          %
        </Grid.Column>
        <Grid.Column width={6} className="epargneChartContainer">
          <Chart>
            <Settings />
            <BarSeries
              id="epargneBrute"
              color={['#737373', '#2193ac']}
              xScaleType={ScaleType.Linear}
              yScaleType={ScaleType.Linear}
              xAccessor="x"
              yAccessors={['y']}
              splitSeriesAccessors={['g']}
              stackAccessors={['x']}
              data={chartData}
              minBarHeight={10}
            />
          </Chart>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EpargneBrute;
