import React from 'react';
import { Grid, Tooltip } from '@jvs-group/jvs-mairistem-composants';
import {
  BarSeries,
  Chart,
  ScaleType,
  Settings,
} from '@elastic/charts';
import { clamp } from 'lodash';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import type PanelDataFromPeriode from '../interfaces/PanelDataFromPeriode';
import type PanelTooltipProps from '../interfaces/panelTooltipProps';
import './EpargneBrute.less';

interface EpargneNetteProps extends PanelTooltipProps{
  data: PanelDataFromPeriode;
}

const EpargneNette = ({
  data,
  tooltip,
}: EpargneNetteProps) => {
  const epargneNette = (data?.epargneBrute ?? 0) - (data?.remboursementCapitalDette ?? 0);
  const cumul = epargneNette + (data?.depenseReelFonctionnement ?? 0);
  const taux = clamp(cumul > 0 ? (epargneNette * 100) / cumul : 0, 0, 100);

  const chartData = [
    {
      x: 'Epargne Nette N-2',
      y: (
        ((data?.exercicePrecedent2?.epargneBrute ?? 0) - (data?.exercicePrecedent2?.remboursementCapitalDette ?? 0))
      ).toFixed(2),
      g: 'Valeur',
    },
    {
      x: 'Epargne Nette N-1',
      y: (
        ((data?.exercicePrecedent?.epargneBrute ?? 0) - (data?.exercicePrecedent?.remboursementCapitalDette ?? 0))
      ).toFixed(2),
      g: 'Valeur',
    },
    { x: 'Epargne Nette', y: epargneNette.toFixed(2), g: 'Valeur :' },
  ];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Tooltip
            message={tooltip}
            trigger={(
              <div className="bold indicateurTitle">
                {`Epargne nette ${data?.anneeExercice}`}
              </div>
            )}
            type="information"
          />

          <br />
          <br />
          Prévisions :
          <br />
          {formatMontant(epargneNette)}
          <br />
          <br />
          Taux:
          {' '}
          {taux?.toFixed(2)}
          %
        </Grid.Column>
        <Grid.Column width={6} className="epargneChartContainer">
          <Chart>
            <Settings />
            <BarSeries
              id="epargneNette"
              color={['#737373', '#2193ac']}
              xScaleType={ScaleType.Linear}
              yScaleType={ScaleType.Linear}
              xAccessor="x"
              yAccessors={['y']}
              splitSeriesAccessors={['g']}
              stackAccessors={['x']}
              data={chartData}
              minBarHeight={10}
            />
          </Chart>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EpargneNette;
