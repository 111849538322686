import React from 'react';

import { isNil } from 'lodash';
import {
  App,
  Feature,
  Menu,
} from '@jvs-group/jvs-mairistem-module';
import { request } from '@jvs-group/jvs-mairistem-store';
import Context from './components/Context/Context';
import Parametres from './entities/Parametres/components/Parametres';
import ElaborationBudget from './entities/Simulation/components/ElaborationBudget';
import { getExercice, getCurrentExercice } from './entities/FeuilleSaisie/utils/exercice';
import StorageKey from './constants/storage';
import 'react-toastify/dist/ReactToastify.css';
import './AppSimulation.less';

const getIdentifiantSimulationFromUrl = () => {
  const { pathname } = window.location;
  // Diviser le chemin en segments
  const segments = pathname.split('/');
  // Récupérer le dernier segment
  const lastSegment = segments.pop() || segments.pop();
  // Pour gérer les barres obliques finales
  return lastSegment;
};

const AppSimulation = () => {
  const identifiantSimulationRef = React.useRef(getIdentifiantSimulationFromUrl());

  const handleOnAuth = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const idExercice = queryParams.get('identifiantExercice');
    const currentExercice = getCurrentExercice();
    if (!isNil(currentExercice) && currentExercice.identifiant === Number(idExercice)) {
      return;
    }

    if (!isNil(idExercice) && isNil(currentExercice)) {
      const exercice = await getExercice(idExercice);
      sessionStorage.setItem(StorageKey.EXERCICE, JSON.stringify(exercice));
    }
  };

  const renderBreadcrumb = (path, { BreadcrumbContainer, BreadcrumbSection, BreadcrumbSeparator }) => (
    <BreadcrumbContainer>
      <BreadcrumbSection key="domain">{path.domain.toLocaleUpperCase()}</BreadcrumbSection>
      <BreadcrumbSeparator />
      <BreadcrumbSection key="feature">{path.feature}</BreadcrumbSection>
    </BreadcrumbContainer>
  );

  return (
    <App
      breadcrumbRenderer={renderBreadcrumb}
      code="HOL_SIMU"
      context={Context}
      group="finances"
      name="Elaboration du budget"
      onAuth={handleOnAuth}
      request={request}
    >
      <Menu.Settings>
        <Feature>
          <Feature.Content>{Parametres}</Feature.Content>
        </Feature>
      </Menu.Settings>
      <Menu
        icon="home"
        name="Elaboration du budget"
        path={`/budgets/${identifiantSimulationRef.current}`}
      >
        <Feature>
          <Feature.Content>
            <ElaborationBudget identifiantSimulation={Number(identifiantSimulationRef.current)} />
          </Feature.Content>
        </Feature>
      </Menu>
    </App>
  );
};
export default AppSimulation;
