import React from 'react';
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Message,
} from '@jvs-group/jvs-mairistem-composants';
import {
  AnalytiqueSearchInput,
  VentilationSearchInput,
  type Analytique,
  type Ventilation,
} from '@jvs-group/jvs-mairistem-comptabilite';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { isNil } from 'lodash';
import ResultatReporteTable from './ResultatReporteTable';
import { getReports, recopieReport } from '../../utils/outil';
import type Reports from '../../interfaces/Reports';
import type Simulation from '../../../Simulation/interfaces/simulation';

interface RecopieReportProps {
  codeAnalytique: string;
  codeVentilation: string;
}

interface ResultatReporteProps {
  onValidate: (data?: unknown) => void;
  simulation: Simulation;
}

const ResultatReporte = ({
  onValidate,
  simulation,
}: ResultatReporteProps) => {
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false);
  const [reports, setReports] = React.useState<Reports>();
  const [values, setValues] = React.useState<RecopieReportProps>();
  const [error, setError] = React.useState<string>();

  const handleChange = (name, result: Pick<Analytique, 'code'> | Pick<Ventilation, 'code'> | null) => {
    setValues((old) => ({
      ...old,
      [name]: result?.code,
    }));
  };

  const handleFetchReports = async () => {
    try {
      setReports(await getReports(simulation.identifiant));
    } catch (e) {
      setError(getErrorMessage(e, 'Erreur lors de la récupération des montants de reports'));
    } finally {
      setLoadingData(false);
    }
  };

  const handleValidate = async () => {
    try {
      setLoadingValidate(true);
      await recopieReport(simulation, values);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la recopie du report'));
    } finally {
      setLoadingValidate(false);
    }
  };

  React.useEffect(() => {
    handleFetchReports();
  }, []);

  if (!isNil(error)) {
    return (
      <Message negative>
        <Message.Header>Erreur</Message.Header>
        <p>{ error }</p>
      </Message>
    );
  }

  return loadingData ? (
    <Dimmer active inverted>
      <Loader size="massive" />
    </Dimmer>
  ) : (
    <>
      <ResultatReporteTable reports={reports} />
      <Form>
        { (!simulation?.exercice?.anaExec || !simulation?.exercice?.venExec)
          && 'Spécifier une répartition analytique :' }
        <Form.Group widths="equal">
          {
            !simulation?.exercice?.anaExec && (
              <Form.Field
                control={AnalytiqueSearchInput}
                /* @ts-expect-error identifiantExercice */
                identifiantExercice={simulation?.identifiantExercice}
                input={{ 'data-testid': 'analytiqueSearchInput' }}
                label="Analytique"
                name="codeAnalytique"
                onChange={handleChange}
                perPage={5}
                placeholder="HCA par défaut"
                value={values?.codeAnalytique}
              />
            )
          }
          {
            !simulation?.exercice?.venExec && (
              <Form.Field
                control={VentilationSearchInput}
                /* @ts-expect-error identifiantExercice */
                identifiantExercice={simulation?.identifiantExercice}
                input={{ 'data-testid': 'ventilationSearchInput' }}
                label="Ventilation"
                name="codeVentilation"
                onChange={handleChange}
                perPage={5}
                placeholder="HCV par défaut"
                value={values?.codeVentilation}
              />
            )
          }
        </Form.Group>
      </Form>
      <div>
        <Button
          content="Reprendre les résultats reportés"
          data-testid="confirmButton"
          disabled={loadingValidate}
          floated="right"
          loading={loadingValidate}
          onClick={handleValidate}
          positive
        />
      </div>
    </>
  );
};

export default ResultatReporte;
